<template>
  <div class="pmp-ads">
    <Teleport to="#header-buttons">
      <v-select
        v-model="currentState"
        class="mr-1 w-m-20"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :clearable="false"
        :searchable="false"
        :options="currentStateOptions"
      />
    </Teleport>
    <div>
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
      >
        <b-skeleton-wrapper :loading="pmpAdsLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <div
            v-if="allPmpAds.length === 0"
            class="text-center mt-5 font-large-1 text-black-50"
          >No Ads Found</div>
          <PmpAdsCollapseItem
            v-for="(pmpAds, index) in allPmpAds"
            :id="pmpAds.id"
            :key="index"
            :approved="pmpAds.approved_by"
            :title="pmpAds.name"
            :time="pmpAds.time"
            :pmp-ad="pmpAds"
            icon="EditIcon"
            :src="null"
            :is-edit-disabled="true"
            @editPmpAds="editPmpAds"
            @refreshList="refreshList"
            @getImpressions="getImpressionsCalculated"
          >
            <div class="heading-elements">
              <b-row>
                <b-col
                  md="10"
                  class="mx-auto"
                >
                  <b-card class="mr-1 mb-1 adds-views-class">
                    <b-skeleton-wrapper :loading="impressioncalculationLoading">
                      <template #loading>
                        <b-skeleton width="100%" />
                        <b-skeleton width="75%" />
                        <b-skeleton width="50%" />
                      </template>
                      <div class="d-block font-weight-bolder text-nowrap stats">
                        Ad Impression Info
                      </div>
                      <div class="demo-vertical-spacing">
                        <div class="stats-div">
                          <label class="labels">Impressions Used</label>
                          <label class="float-right labels">{{
                            calculatedImpressions
                              ? calculatedImpressions.ImpressionsUsed
                              : "--"
                          }}</label>
                        </div>
                        <div class="stats-div">
                          <label class="labels">Impressions to be Used</label>
                          <label class="float-right labels">{{
                            calculatedImpressions
                              ? calculatedImpressions.ImpressionsToBeUsed
                              : "--"
                          }}</label>
                        </div>
                        <div class="stats-div">
                          <label class="labels">Impressions Expired</label>
                          <label class="float-right labels">{{
                            calculatedImpressions? calculatedImpressions.ImpressionsExpired : "--"
                          }}</label>
                        </div>
                        <div class="stats-div">
                          <label class="labels">Details</label>
                          <b-button
                            variant="primary"
                            class="float-right bn btn-sm"
                            @click="openSchedulerDetails(pmpAds.id)"
                          >
                            See Details
                          </b-button>
                        </div>
                      </div>
                    </b-skeleton-wrapper>
                  </b-card>
                </b-col>
                <!-- Column: Devices -->
                <!-- <b-col md="12">
                  <div class="device">
                    Devices
                  </div>
                  <div
                    v-if="!pmpAds.length"
                    style="text-align: center; font-size: 20px!important;"
                    class="font-weight-normal mb-2"
                  >
                    This information will only be available after ad is played successfully
                  </div>
                </b-col> -->

              </b-row>
            </div>
          </PmpAdsCollapseItem>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
    <div
      v-if="allPmpAds.length"
      class="mt-1"
    >
      <CustomPagination
        :total="paginationObject.total"
        :per-page="paginationObject.limit"
        :page="paginationObject.offset"
        @perPageChanged="perPageChanged"
        @pageChanged="pageChanged"
      />
    </div>
  </div>
</template>
<script>
import {
  BSkeletonWrapper,
  BSkeleton,
  VBTooltip,
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import Teleport from 'vue2-teleport'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import PmpAdsCollapseItem from '@/components/pmp-ads/PmpAdsCallapseItem.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile, showErrorMessage, showToast } from '@/common/global/functions'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import TimeLimitCondition from '@/common/config'
import CustomPagination from '@/components/common/CustomPagination.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import PmpAdsStatusType from '@/common/enums/PmpAdsStatusEnum'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  name: 'Index',
  components: {
    CustomPagination,
    PmpAdsCollapseItem,
    AppCollapse,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    Teleport,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](
      MemberPermissions.PMP_ADS,
    )
    const isUserAdmin = store.getters['user/getUserRole']
    if (memberPermission || (isUserAdmin === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  data() {
    return {
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
      },
      currentState: { key: 1, name: 'Pending', value: PmpAdsStatusType.PENDING },
      timeState: 'pending',
      MediaType,
      pmpAdsLoading: false,
      id: '',
      allPmpAds: [],
      selectMode: 'single',
      perViewCost: TimeLimitCondition.costPerView,
      currentStateOptions: [
        { key: 1, name: 'Pending', value: PmpAdsStatusType.PENDING },
        { key: 2, name: 'Approved', value: PmpAdsStatusType.APPROVED },
        { key: 3, name: 'Rejected', value: PmpAdsStatusType.REJECTED },
      ],
      groupID: this.$route.params.userId,
      status: PmpAdsStatusType.PENDING,
      impressioncalculationLoading: false,
      calculatedImpressions: null,
    }
  },
  watch: {
    currentState: {
      handler(value) {
        if (value.value === PmpAdsStatusType.PENDING) {
          this.status = PmpAdsStatusType.PENDING
        } else if (value.value === PmpAdsStatusType.APPROVED) {
          this.status = PmpAdsStatusType.APPROVED
        } else {
          this.status = PmpAdsStatusType.REJECTED
        }
        this.setPaginationToDefault()
        this.getAllPmpAds(this.paginationObject)
      },
    },
  },
  mounted() {
    this.getAllPmpAds(this.paginationObject)
  },
  methods: {
    convertLocation(location) {
      const [lng, lat] = location?.split('(')?.[1].split(' ')
      return `lng = ${lng}, lat = ${lat.replace(')', '')}`
    },
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    setPaginationToDefault() {
      this.paginationObject = {
        offset: 1,
        limit: 5,
        total: 0,
      }
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllPmpAds(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllPmpAds(this.paginationObject)
    },
    async getAllPmpAds({ offset, limit }) {
      this.pmpAdsLoading = true
      try {
        const response = await this.$store.dispatch('pmpAds/getAllPmpCampaignsOfAllUsersByAdmin', { limit, offset: offset - 1, status: this.status })
        this.allPmpAds = response.results
        this.paginationObject.total = response.total
        this.pmpAdsLoading = false
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('External Ads', 'You are not authorized for this action!', 'danger')
          this.pmpAdsLoading = false
        }
      } finally {
        this.pmpAdsLoading = false
      }
    },
    editPmpAds(obj) {
      this.$router.push(`/users/${this.$route.params.userId}/user-pmp-ads/edit-ads/${obj.id}`)
    },
    async onRowSelected(items, index) {
      if (items[0] && items[0].media) {
        const mediaArray = Object.assign([], [items[0].media])
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      await this.$refs.mediaTable[index].clearSelected()
    },
    refreshList() {
      this.getAllPmpAds(this.paginationObject)
    },
    openSchedulerDetails(pmpAds) {
      this.$router.push(`/admin/pmpAdsList/${pmpAds}/analytics`)
    },
    async getImpressionsCalculated(pmpAdId) {
      this.impressioncalculationLoading = true
      this.calculatedImpressions = await this.$store.dispatch('pmpAds/getAdminImpressionCalculationPmpScheduler', { pmpAdId })
      this.impressioncalculationLoading = false
    },
  },
}
</script>

      <style lang="scss">
      .pmp-ads {
        #time-state-select {
          .vs__selected-options {
            min-width: 100px !important;
          }
          .vs__dropdown-menu {
            min-width: 100px !important;
          }
          .vs__dropdown-toggle {
            min-width: 100px !important;
            background-color: white !important;
          }
        }
        .vs__dropdown-toggle {
          background-color: white !important;
        }
        #per-page-selector {
          .vs--single .vs__dropdown-toggle {
            max-height: 37px !important;
          }
          .vs__dropdown-toggle {
            min-width: 90px !important;
          }
        }
        .image {
          width: 32px;
          height: 32px;
          border: 2px solid#ececec;
          border-radius: 5px !important;
          box-shadow: 0 1px 7px#0000002e;
        }
        .demo-vertical-spacing {
          margin-bottom: 5px;
        }
        .switches {
          border-radius: 25px;
        }
        .labels {
          font-size: 14px;
          letter-spacing: 0px;
          color: #414141;
          font-weight: 500;
          font-family: "Montserrat";
          line-height: 2;
        }
        .stats-div {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .stats {
          font-size: 14px;
          letter-spacing: 0px;
          color: #1f58b5;
          font-weight: 600;
          font-family: "Montserrat";
        }
        .media-card {
          max-height: 225px !important;
          min-height: 225px !important;
        }
        .adds-views-class {
          min-height: 225px;
        }
        .per-page-selector {
          width: 90px;
        }
        .cost-class {
          font-size: 14px;
          letter-spacing: 0px;
          color: #414141;
          font-weight: 600;
          font-family: "Montserrat";
        }
        .top-margin-class {
          margin-top: -3.5rem;
          @media (max-width: 848px) {
            margin-top: 0px !important;
          }
        }
      }
      </style>
